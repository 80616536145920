<template>
  <Box flex direction="col" spaceY="6" alignItems="start">
    <FormSection title="Select trigger source type">
      <FormControl :id="getId('lassoTrigger.sourceTypeId')" :disabled="!isDraft" hideLabel>
        <Box flex alignItems="center" justify="start" spaceX="2">
          <FieldControl id="sourceTypeProgrammatic" v-slot="{ id }" label="Programmatic" width="120px" :disabled="!isDraft">
            <Radio
              :id="id"
              v-model="sourceTypeIdInternal"
              :value="AdGroupSourceType.PROGRAMMATIC"
              :disabled="!isDraft"
              name="sourceTypeProgrammatic"
            />
          </FieldControl>

          <FieldControl id="sourceTypeEmail" v-slot="{ id }" label="Email" :disabled="!isDraft">
            <Radio
              :id="id"
              v-model="sourceTypeIdInternal"
              :value="AdGroupSourceType.EMAIL"
              :disabled="!isDraft"
              name="sourceTypeEmail"
            />
          </FieldControl>
        </Box>
      </FormControl>
    </FormSection>

    <FormSection v-if="sourceTypeId" title="Select trigger source(s)" width="100%">
      <FormError :id="getId('lassoTrigger.sourceGroups')" pb="2" :errorText="sourceGroupsError" />

      <ErrorState v-if="errorAdGroups" :error="errorAdGroups" inline @retry="requestAdGroups()" />
      <SourceGroupSelector
        v-else
        :loading="loadingAdGroups"
        :validating="validatingAdGroups"
        :selectedItems="selectedSourceGroups"
        :items="fetchedAdGroups"
        :disabled="!isDraft || noSourceAdGroups"
        @listItemSelected="handleSourceGroupSelection"
        @removeSelectedItem="handleSourceGroupRemove"
      />
    </FormSection>

    <FormSection v-if="sourceTypeId" title="Select engagement">
      <FormControl :id="getId('lassoTrigger.triggerEngagementId')" v-slot="{ id }" label="Engagement" width="220px" required :disabled="!sourceTypeId || !isDraft">
        <Select
          :id="id"
          :modelValue="triggerEngagementId || ''"
          :options="triggerEngagementIdOptions"
          :disabled="!isDraft"
          placeholder="Select"
          width="160px"
          @update:modelValue="changeTriggerAction"
        />
      </FormControl>
    </FormSection>

    <ErrorState
      v-if="validationError"
      error="Failed to validate source ad groups"
      inline
      @retry="validateSourceAdGroupIds()"
    />

    <Box v-if="triggerEngagementId === AdGroupTriggerEngagement.CUSTOM_DYNAMIC">
      <Box
        v-for="(item, index) in engagements"
        :key="item.id"
        class="relative"
      >
        <Box
          flex
          wrap="wrap"
          direction="row"
          alignItems="center"
          class="triggers-card"
        >
          <Box flex wrap="wrap" alignContent="center" spaceX="4" spaceY="4">
            <Box pt="2">
              <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
                user who
              </Typography>
            </Box>
            <FormControl
              :id="getId(`lassoTrigger.engagements[${index}].action`)"
              v-slot="{ error, id }"
              hideLabel
              :fullWidth="false"
              width="160px"
            >
              <Box width="160px">
                <Select
                  :id="id"
                  :modelValue="item.action"
                  :options="actionOptions"
                  width="160px"
                  placeholder="Select"
                  :error="error"
                  :name="id"
                  @update:modelValue="updateEngagement(index, { action: $event })"
                />
              </Box>
            </FormControl>
            <Box pt="2">
              <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
                the source ad
              </Typography>
            </Box>
            <FormControl
              :id="getId(`lassoTrigger.engagements[${index}].quantity`)"
              v-slot="{ id }"
              hideLabel
              :fullWidth="false"
              width="160px"
            >
              <Box width="160px">
                <InputText
                  :id="id"
                  :modelValue="item.quantity"
                  :mask="numberMask"
                  :name="id"
                  @update:modelValue="updateEngagement(index, { quantity: $event })"
                />
              </Box>
            </FormControl>
            <Box pt="2">
              <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
                times in
              </Typography>
            </Box>
            <FormControl
              :id="getId(`lassoTrigger.engagements[${index}].periodValue`)"
              v-slot="{ id }"
              hideLabel
              :fullWidth="false"
              width="160px"
            >
              <Box width="160px">
                <InputText
                  :id="id"
                  :modelValue="item.periodValue"
                  :mask="numberMask"
                  :name="id"
                  @update:modelValue="updateEngagement(index, { periodValue: $event })"
                />
              </Box>
            </FormControl>
            <FormControl
              :id="getId(`lassoTrigger.engagements[${index}].period`)"
              v-slot="{ error, id }"
              hideLabel
              :fullWidth="false"
              width="160px"
            >
              <Box width="160px">
                <Select
                  :id="id"
                  :modelValue="item.period ?? undefined"
                  :options="timePeriodOptions"
                  width="160px"
                  placeholder="Select"
                  :error="error"
                  :name="id"
                  @update:modelValue="updateEngagement(index, { period: $event ?? null })"
                />
              </Box>
            </FormControl>
            <ButtonIcon v-if="canDeleteEngagement" icon="close" class="engagement-delete" @click="removeEngagement(index)" />
          </Box>
        </Box>

        <Box v-if="canDeleteEngagement && index === 0" width="80px" py="4">
          <Select
            :id="getId('lassoTrigger.logicCondition')"
            v-model="logicCondition"
            :options="logicConditionsOptions"
            width="80px"
            name="logicCondition"
          />
        </Box>
      </Box>

      <Box v-if="canAddEngagement" mt="6">
        <AddLink @click="addEngagement()">
          Engagement
        </AddLink>
      </Box>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  AddLink,
  Box,
  ButtonIcon,
  ErrorState,
  FieldControl,
  FormControl, FormError,
  FormSection,
  InputText,
  Radio,
  Select,
  SelectOptionType,
  Typography,
} from '@lasso/luikit'
import { AdGroupSourceType, AdGroupTriggerEngagement, ChannelType } from '@lasso/api-activation/activation'
import { useApi, useApiManual } from '@lasso/shared/hooks'
import { objValues } from '@lasso/shared/utils'

import { GetSourceAdGroupsRequest, SourceAdGroup, channelActApiToLegacy, useActivationLegacyApi } from '@lasso/api-activation/activation/legacy'
import { numberMask } from '@lasso/shared/consts'

import { useCardAudiences } from '../useCardAudiences'
import {
  AudienceEngagementAction,
  AudienceLogicConditions,
  AudienceTimePeriod,
  SupportedAdGroupTriggerEngagement,
} from '../types'

import SourceGroupSelector from './SourceGroupSelector.vue'

const emit = defineEmits<{
  updateCpm: [number]
}>()

const timePeriodOptions = [
  {
    label: 'Day',
    value: AudienceTimePeriod.Day,
  },
]

const legacyApi = useActivationLegacyApi()

const {
  marketerId,
  adGroupAccountId,
  channelId,
  isLassoTrigger,
  isDraft,
  shouldFetchByAccountId,

  triggerEngagementId,
  sourceGroups,
  engagements,
  sourceTypeId,
  sourceGroupsError,
  logicCondition,
  addEngagement,
  updateEngagement,
  removeEngagement,

  getId,
} = useCardAudiences()!

const sourceTypeIdInternal = computed({
  get: () => sourceTypeId.value ?? '',
  set: (value) => {
    sourceTypeId.value = value || null
  },
})

const actionMap: { [K in AudienceEngagementAction]: SelectOptionType<AudienceEngagementAction> } = {
  [AudienceEngagementAction.Viewed]: {
    value: AudienceEngagementAction.Viewed,
    label: 'Viewed',
  },
  [AudienceEngagementAction.Clicked]: {
    value: AudienceEngagementAction.Clicked,
    label: 'Clicked',
  },
}

const actionOptions = objValues(actionMap)

const sourceTypeLegacyApiMap: Record<AdGroupSourceType, number> = {
  [AdGroupSourceType.PROGRAMMATIC]: 1,
  [AdGroupSourceType.EMAIL]: 2,
}

const triggerEngagementIdMap: { [K in SupportedAdGroupTriggerEngagement]: SelectOptionType<K> } = {
  [AdGroupTriggerEngagement.VIEW]: {
    value: AdGroupTriggerEngagement.VIEW,
    label: 'View',
  },
  [AdGroupTriggerEngagement.CLICK]: {
    value: AdGroupTriggerEngagement.CLICK,
    label: 'Click',
  },
  [AdGroupTriggerEngagement.CUSTOM_DYNAMIC]: {
    value: AdGroupTriggerEngagement.CUSTOM_DYNAMIC,
    label: 'Custom',
  },
}
const triggerEngagementIdOptions = objValues(triggerEngagementIdMap)

const logicConditionsMap: { [K in AudienceLogicConditions]: SelectOptionType<AudienceLogicConditions> } = {
  [AudienceLogicConditions.AND]: {
    value: AudienceLogicConditions.AND,
    label: 'AND',
  },
  [AudienceLogicConditions.OR]: {
    value: AudienceLogicConditions.OR,
    label: 'OR',
  },
}

const logicConditionsOptions = objValues(logicConditionsMap)

const canDeleteEngagement = computed(() => engagements.value.length > 1)
const canAddEngagement = computed(() => engagements.value.length < 2)

const changeTriggerAction = (newTriggerAction: AdGroupTriggerEngagement) => {
  engagements.value = []
  triggerEngagementId.value = newTriggerAction

  if (triggerEngagementId.value === AdGroupTriggerEngagement.CUSTOM_DYNAMIC) {
    addEngagement()
  }
}

const getSourceAdGroupData = computed((): [GetSourceAdGroupsRequest] | null => {
  if (!isLassoTrigger.value || !marketerId.value || !adGroupAccountId.value || !sourceTypeId.value) {
    return null
  }

  return [{
    marketerId: marketerId.value,
    advertiserId: shouldFetchByAccountId ? adGroupAccountId.value : 0,
    campaignId: '',
    keyword: '',
    sourceTypeId: sourceTypeLegacyApiMap[sourceTypeId.value],
  }]
})
const { loading: loadingAdGroups, data, error: errorAdGroups, request: requestAdGroups } = useApi(
  legacyApi.adGroup.getSourceAdGroups,
  getSourceAdGroupData,
  { refetch: true },
)

const {
  request: validateSourceAdGroups,
  loading: validatingAdGroups,
  error: validationError,
} = useApiManual(legacyApi.adGroup.validateSourceAdGroupIdsList)

const fetchedAdGroups = computed(() => data.value?.data ?? [])

const noSourceAdGroups = computed(() => !!sourceTypeId.value && !loadingAdGroups.value && !fetchedAdGroups.value.length)

const validateSourceAdGroupIds = async () => {
  if (!sourceGroups.value.length || !sourceTypeId.value) {
    sourceGroupsError.value = ''

    return
  }

  const response = await validateSourceAdGroups({
    marketerId: marketerId.value,
    advertiserId: adGroupAccountId.value,
    sourceTypeId: sourceTypeLegacyApiMap[sourceTypeId.value!],
    adGroupIds: sourceGroups.value.join(','),
    channelId: channelActApiToLegacy[channelId.value],
  })

  if (!response) {
    return
  }

  if (response.data.message && response.data.message !== 'Valid IDs') {
    sourceGroupsError.value = response.data.message
  }
  else {
    const { emailMaxDataCPM, xandrMaxDataCPM } = response.data
    const dataCpm = channelId.value === ChannelType.EMAIL ? emailMaxDataCPM : xandrMaxDataCPM
    if (dataCpm) {
      emit('updateCpm', Number((dataCpm).toFixed(2)))
    }

    sourceGroupsError.value = ''
  }
}

const handleSourceGroupSelection = async (group: SourceAdGroup) => {
  const adGroupId = group.adGroupId.toString()

  if (sourceGroups.value.includes(adGroupId)) {
    sourceGroups.value = sourceGroups.value.filter(id => id !== adGroupId)
  }
  else {
    sourceGroups.value = [...sourceGroups.value, adGroupId]
  }

  await validateSourceAdGroupIds()
}

const handleSourceGroupRemove = (group: SourceAdGroup) => {
  sourceGroups.value = sourceGroups.value.filter(id => id !== group.adGroupId.toString())
}

const selectedSourceGroups = computed(() => {
  return fetchedAdGroups.value.filter(id => sourceGroups.value.includes(id.adGroupId.toString()))
})
</script>

<style scoped>
.triggers-card {
  @apply border border-divider rounded-xl py-[29px] px-6 gap-4 pr-10;
}

.engagement-delete {
  @apply absolute right-4 top-4;
}
</style>
