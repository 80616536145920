<template>
  <Card title="Audience" collapse opened withDivider>
    <template v-if="!loading && !error" #headerActions>
      <FieldControl :id="getId('isLassoTrigger')" v-slot="{ id }" label="Triggers" :disabled="!isDraft">
        <Toggle
          :id="id"
          v-model="isLassoTrigger"
          :disabled="!isDraft"
          size="xs"
          color="secondary"
        />
      </FieldControl>
    </template>

    <Transitions name="slide">
      <Box v-if="loading">
        <Skeleton height="200px" />
      </Box>
      <ErrorState v-else-if="error" :error="error" @retry="request()" />
      <Box v-else spaceY="6" flex direction="col">
        <AudienceTriggers v-if="isLassoTrigger" @updateCpm="updateCpm" />

        <AudienceDataOnly v-if="isDataOnly" />

        <Box v-if="!audienceSelectorHidden">
          <Box v-if="isDataOnly || isLassoTrigger" pb="4">
            <Typography variant="h6" color="textPrimary">
              {{ maxAudiences === 1 ? 'Select audience' : 'Select audiences' }}
            </Typography>
          </Box>

          <Box grow="grow">
            <AudienceSelect
              v-model:audiences="audiences"
              :max="maxAudiences"
              :disabled="disabledEdit"
              :targetable="!isDataOnly"
              @select="onAudienceSelect($event)"
            />
          </Box>
        </Box>
      </Box>
    </Transitions>
  </Card>
</template>

<script setup lang="ts">
import { Box, Card, ErrorState, FieldControl, Skeleton, Toggle, Transitions, Typography } from '@lasso/luikit'
import { computed } from 'vue'

import { AdGroupAudience } from './types'
import { buildAudienceCpm } from './utils'
import AudienceTriggers from './Triggers/AudienceTriggers.vue'
import AudienceSelect from './AudienceSelect.vue'
import AudienceDataOnly from './AudienceDataOnly.vue'
import { useCardAudiences } from './useCardAudiences'

const { isDataOnly, audiences, cpm, isLassoTrigger, isSourceAdGroup, isDraft, loading, error, request, getId } = useCardAudiences()!

const updateCpm = (newCpm: number) => {
  cpm.value = newCpm
}

const onAudienceSelect = (audience: AdGroupAudience) => {
  if (!isDataOnly.value) {
    return
  }

  const audienceCpm = buildAudienceCpm(audience, isDataOnly.value)

  if (audienceCpm) {
    updateCpm(audienceCpm)
  }
}

const audienceSelectorHidden = computed(() => {
  return isLassoTrigger.value && isDataOnly.value && audiences.value.length === 0
})

const maxAudiences = computed(() => {
  return isDataOnly.value ? 1 : Infinity
})

const disabledEdit = computed(() => (isDataOnly.value && isLassoTrigger.value) || isSourceAdGroup.value)
</script>
