<template>
  <Skeleton v-if="loading" height="300px" />
  <SelectPanel v-else :empty="filteredItems.length === 0">
    <template #search>
      <SelectPanelInput v-model="searchQuery" />
    </template>

    <template #items>
      <SelectPanelVirtualScroller
        v-slot="{ item }"
        :items="filteredItems"
        keyField="adGroupId"
      >
        <SelectPanelItem
          :modelValue="isItemSelected(item.adGroupId)"
          :chip="item.adGroupId"
          :searchQuery="searchQuery"
          :label="item.adGroupName"
          :disabled="disabled"
          singleLine
          @update:modelValue="handleListItemSelected(item)"
        />
      </SelectPanelVirtualScroller>
    </template>

    <template #selectedItemsTitle>
      Selected audiences
    </template>

    <template v-if="selectedListItemsReverse.length > 0" #selectedItems>
      <Skeleton v-if="validating" height="300px" />
      <template v-else>
        <SelectPanelSelectedItem
          v-for="item in selectedListItemsReverse"
          :key="item.adGroupId"
          :label="item.adGroupName"
          :disabled="disabled"
          @remove="emits('removeSelectedItem', item)"
        />
      </template>
    </template>
  </SelectPanel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { SelectPanel, SelectPanelInput, SelectPanelItem, SelectPanelSelectedItem, SelectPanelVirtualScroller, Skeleton } from '@lasso/luikit'
import { objFromEntries } from '@lasso/shared/utils'
import { refDebounced } from '@vueuse/core'

import { SourceAdGroup } from '@lasso/api-activation/activation/legacy'

const props = withDefaults(defineProps<{
  items: SourceAdGroup[]
  selectedItems: SourceAdGroup[]
  loading?: boolean
  disabled?: boolean
  validating?: boolean
}>(), {
  loading: false,
  disabled: false,
  validating: false,
})

const emits = defineEmits<{
  listItemSelected: [SourceAdGroup]
  removeSelectedItem: [SourceAdGroup]
}>()

const searchQuery = ref('')
const searchQueryDebounced = refDebounced(searchQuery, 500)

const filteredItems = computed(() => {
  return props.items.filter(({ adGroupId, adGroupName }) => {
    return adGroupName?.toLowerCase().includes(searchQueryDebounced.value.toLowerCase()) || adGroupId?.toString() === searchQueryDebounced.value
  })
})

const selectedListItemsReverse = computed(() => {
  return props.selectedItems.slice().reverse()
})

const selectedItemsMap = computed(() => {
  return objFromEntries(
    props.selectedItems.map(item => [item.adGroupId, item] as const),
  )
})

const isItemSelected = (id: number) => {
  return Boolean(selectedItemsMap.value[id])
}

const handleListItemSelected = (item: SourceAdGroup) => {
  emits('listItemSelected', item)
}
</script>

<style scoped>
.scroller :deep(.scroller-item) {
  @apply border-b border-base-500 last-of-type:border-b-0;
}
</style>
